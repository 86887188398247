import React, { useState, useEffect } from 'react'

import './App.css';
import logo from './images/logo2.svg';
import bg from './images/lawyer-with-weighing-scales.jpg';
import LeadForm from './Form.js';
import {Carousel, Form, Button, Col, Container, Row, Navbar, Nav} from 'react-bootstrap';
import WhatsAppIcon from './whatsapp/WhatsAppIcon';

function App() { 
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const bgStyles = {backgroundImage: 'url('+bg+')'};
  const [otherActive, setOtherActive] = useState(false);
  const [start, setStart] = useState(false);
  const [step, setStep] = useState(0);
  const [values, setValues] = useState([]);

  useEffect(() => {
    console.log(values);
  });
  
  const renderStepFields = (step_data) => {
      switch (step_data.type) {
          case "select":
            return renderSelectField(step, step_data.select);
          break;
          case "text":
            return renderTextField(step);
          break;
          case "final":
            return renderFinalField();
          break;
      }
  }
  
  const renderFinalField = () => {
      return (
        <Row>
            <Col md="6" className="align-self-center">
                <p>{steps[step].desc}</p>
                <h5>{steps[step].desc2}</h5>
            </Col>
            <Col md="6">
                <LeadForm additional={values} />
            </Col>
        </Row>
      )
  }
  
  const renderTextField = (step) => {
      return (
        <Form.Control type="text" name={"step-"+step+"-text"} onKeyUp={textHandler} defaultValue={(values[step] == 'другое') ? '' : values[step]} />
      )
  }
  
  const renderSelectField = (step, list) => {
      return (
        <div>
            <Row className="step-select-field">
                {list.map((e, i) => (<Col md="6"><div className="form-check-container"><Form.Check onChange={selectHandler} label={e} checked={values[step] == e} type="radio" name={"step-"+step+"-select"} id={"step-"+step+"-"+i+"-select"} /></div></Col>))}
            </Row>
            { otherActive ? (
            <div className="step-select-field">
                <h5>Опишите подробнее</h5>
                <Form.Control type="text" name={"step-"+step+"-text"} onKeyUp={textHandler} defaultValue={values[step]} />
            </div>
            ) : '' }
        </div>
      )
  }
  
  const prevBtnHandler = () => {
      setStep(step > 0 ? (step-1) : step);
  }
  
  const nextBtnHandler = () => {
      setStep((step + 1) < steps.length ? (step+1) : step);
      setOtherActive(false);
  }

  const selectHandler = (e) => {
      let value = e.target.nextSibling.innerText;
      
      values[step] = e.target.nextSibling.innerText;
      setValues(values);
      
      if (value != 'другое')
        nextBtnHandler();
      else {
        setOtherActive(true);
      }
  }
  
  const textHandler = (e) => {
      values[step] = e.target.value;
      setValues(values);
      
      forceUpdate();
  }
  
  const steps = [
    {
        title: 'Укажите пожалуйста, как называется компания с которой у Вас возник финансовый конфликт?',
        type: 'text',
        required: true
    },{
        title: 'Какой основной вид деятельности обманувшей Вас компании?',
        type: 'select',
        required: true,
        select: [
            'криптовалютная биржа',
            'инвестиционный фонд',
            'букмекер',
            'брокер',
            'другое'
        ]
    },{
        title: 'Как давно мошенники присвоили Ваши деньги?',
        type: 'select',
        required: true,
        select: [
            'менее месяца назад',
            'менее полугода назад',
            'менее года назад',
            'прошло более года'
        ]
    },{
        title: 'Как мошенники оправдали кражу Ваших средств?',
        type: 'select',
        required: true,
        select: [
            'мой счет/депозит намеренно слили/обнулили',
            'мне отказываются выводить деньги постоянно придумывая разные предлоги'
        ]
    },{
        title: 'О какой сумме ущерба идет речь?',
        type: 'select',
        required: true,
        select: [
            'до 1000$',
            'от 1000$ до 5000$',
            'от 5000$ до 10000$',
            'более 10000$'
        ]
    },{
        title: 'Каким образом Вы передавали деньги мошенникам?',
        type: 'select',
        required: true,
        select: [
            'наличными',
            'банковским переводом',
            'криптовалютной транзакцией',
            'другим способом'
        ]
    },{
        title: 'Остались ли у Вас на руках документальные подтверждения сотрудничества с мошенниками?',
        type: 'select',
        required: true,
        select: [
            'нет, ничего нет',
            'да, есть договор, но нет подтверждения передачи денег',
            'есть договор, квитанции и счета'
        ]
    },{
        title: 'Дополнительные важные сведения',
        required: false,
        type: 'text'
    },{
        title: 'Оставьте свои контактные данные и отправьте нам свою заявку. ',
        desc: 'Мы соберем всю необходимую информацию и предоставим Вам абсолютно бесплатную консультацию. Напоминаем, мы оказываем услуги без предоплаты. Наше вознаграждение - только после возврата Ваших средств!',
        desc2: 'Верните свои деньги как можно быстрее, не теряйте время!',
        required: false,
        type: 'final'
    }
  ];
  
  const renderSteps = (
    <div>
        <h4 className="mb-5">{steps[step].title}</h4>
        <div className="step-content pb-5">
            {renderStepFields(steps[step])}
        </div>
        <Row className="steps-info p-4 mt-5">
            <Col md="8" className="col-12 text-center text-md-left mb-3 mb-md-0">Шаг {step+1} из {steps.length}</Col>
            <Col md="4" className="col-12 text-center text-md-right">
                {(step != 0) ? (<Button variant="warning" className="mr-2" onClick={prevBtnHandler}>
                    Назад
                </Button>) : ''}
                {((step+1) != steps.length) ? (<Button onClick={nextBtnHandler} disabled={(steps[step].required == true) && !values[step]}>
                    Далее
                </Button>) : ''}
            </Col>
        </Row>
        <Row className="steps-info p-4 mt-1">
            Чтобы ознакомится с Вашим случаем и предоставить Вам бесплатную консультацию, просим ответить на несколько вопросов. Обращаем Ваше внимание на то, что мы не берем никакой предоплаты со своих клиентов, а вся полученная от Вас информация является строго конфиденциальной.
        </Row>
    </div>
  )
  return start ? (
    <div className="quiz-container justify-content-center p-md-5 d-flex">
        <Container className="quiz-inner p-5">
            {renderSteps}
        </Container>
        <WhatsAppIcon />
    </div>
  ) : (
    <div className="App">
        <Row className="fullscreen-block m-0" style={bgStyles}>
            <Col md="8" lg="6" className="offset-0 offset-md-1 offset-lg-1 align-self-center p-5 p-md-4">
                <h2 className="mb-3">Юридическая помощь в возврате средств</h2>
                <h2 className="mb-3">Профессионально. Быстро. Без предоплат</h2>
                <p className="mb-5">Поможем в возврате денег от брокера-мошенника, финансовой пирамиды, фейкового криптовалютного кошелька и в других случаях мошенничества.</p>
                <a className="glass-btn text-center" onClick={() => setStart(true)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Приступить к возврату средств
                </a>
            </Col>
        </Row>
        <WhatsAppIcon />
    </div>
  );
}

export default App;
